import React from 'react'
import styled from 'styled-components'
import { Link } from 'i18n/Link'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Page } from 'components/layout/Page'
import { Container } from 'components/ui-kit/Container'
import { Title } from 'components/ui-kit/title'
import { breakpoints } from 'components/ui-kit/globalValue'
import { ImageBox } from 'components/ui-kit/ImageBox'
import { useTranslations } from 'i18n/useTranslations'
import { MDXRenderer } from 'components/ui-kit/MDXRenderer'

const DescriptionElement = styled.li`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding-left: 12%;
    margin-bottom: 1em;
    @media (min-width: ${breakpoints.tablet}) {
        margin-left: 20px;
        margin-right: 20px;
    }
    :after {
        position: absolute;
        top: 7px;
        left: 0;
        width: 10%;
        border-top: 2px solid;
        content: '';
    }
`

const DescriptionBottom = styled.div`
    padding-bottom: 20px;
    line-height: 1.2;
    @media (min-width: ${breakpoints.tablet}) {
        padding: 0 12% 20px 12%;
        margin-left: 20px;
        margin-right: 20px;
    }
`

const LogisticPage = (props) => {
    const translations = useTranslations()
    const { pageData } = props.data

    return (
        <Page>
            <Helmet>
                <title>
                    {pageData.frontmatter.title} - {translations.logo} -
                    {translations.siteTitle}
                </title>
                <meta
                    name="description"
                    content={pageData.frontmatter.description}
                />
            </Helmet>
            <Container>
                <Title as="h1" view="underlined">
                    {translations.logistics}
                </Title>
                <Title as="h4">{translations.logisticsTopTitle}</Title>
                <MDXRenderer
                    components={{
                        li: DescriptionElement,
                        p: DescriptionBottom,
                        h4: Title,
                    }}
                >
                    {pageData.code.body}
                </MDXRenderer>
            </Container>
            <Link
                to="/logistic-image/"
                state={{
                    modal: true,
                }}
            >
                <ImageBox images={props.data.images.edges} />
            </Link>
            <Container>
                <Title as="h4">{translations.bottomTitle}</Title>
            </Container>
        </Page>
    )
}
export default LogisticPage

export const query = graphql`
    query($locale: String! = "uk") {
        images: allFile(filter: { relativeDirectory: { eq: "logistics" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        fluid(
                            maxWidth: 1200
                            maxHeight: 900
                            cropFocus: CENTER
                        ) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        pageData: mdx(
            fields: { locale: { eq: $locale }, pageName: { eq: "logistic" } }
        ) {
            frontmatter {
                title
                description
            }
            code {
                body
            }
        }
    }
`
